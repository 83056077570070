

























import Vue, {PropType} from 'vue';

import GameRegistry from '../GameRegistry';
import Partition from '../ebx/Partition';
import Field from '../ebx/Field';

export default Vue.extend({
    name: 'ArrayProperty',
    props: {
        registry: {
            type: Object as PropType<GameRegistry>,
            required: true,
        },
        partition: {
            type: Object as PropType<Partition>,
            required: true,
        },
        field: {
            type: Object as PropType<Field<any>>,
            required: true,
        },
    },
    data() {
        return {
            visible: this.field.value.length <= 10 && ['propertyConnections', 'linkConnections', 'eventConnections'].indexOf(this.field.name) < 0,
        };
    },
});
