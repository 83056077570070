












import Vue, {PropType} from 'vue';
import {Node} from 'rete';

import Instance from '../../ebx/Instance';

export default Vue.extend({
    name: 'CompareNode',
    props: {
        node: {
            type: Object as PropType<Node>,
            required: true,
        },
        instance: {
            type: Object as PropType<Instance>,
            required: true,
        },
    },
    computed: {
        operator(): string {
            switch (this.instance.fields.operator.enumValue) {
                case 'CompareOp_Equal':
                    return '==';
                case 'CompareOp_NotEqual':
                    return '!=';
                case 'CompareOp_Greater':
                    return '>';
                case 'CompareOp_Less':
                    return '<';
                case 'CompareOp_GreaterOrEqual':
                    return '>=';
                case 'CompareOp_LessOrEqual':
                    return '<=';
                default:
                    return '???';
            }
        },
    },
});
