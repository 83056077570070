












import Vue, {PropType} from 'vue';
import {Node} from 'rete';

import Instance from '../../ebx/Instance';

export default Vue.extend({
    name: 'RandomDelayNode',
    props: {
        node: {
            type: Object as PropType<Node>,
            required: true,
        },
        instance: {
            type: Object as PropType<Instance>,
            required: true,
        },
    },
    computed: {
        minDelay(): string {
            return `${this.instance.fields.minDelay.value}`;
        },
        maxDelay(): string {
            return `${this.instance.fields.maxDelay.value}`;
        },
    },
});
