











import Vue from 'vue';

import Partition from '../ebx/Partition';

import Graph from './graph/Graph.vue';
import GameRegistry from '../GameRegistry';

export default Vue.extend({
    name: 'FullscreenGraph',
    props: [
        'game',
        'path',
    ],
    data(): {
        registry: GameRegistry;
        partition: Partition | null;
    } {
        return {
            registry: this.$registries[this.game],
            partition: null,
        };
    },
    mounted() {
        this.registry.resolveFile(this.path)
            .then(partition => this.partition = partition);
    },
    components: {
        Graph,
    },
});
