





































































import Vue from 'vue';

import Partition from '../ebx/Partition';
import Instance from '../ebx/Instance';

import HierarchyEntry from './HierarchyEntry.vue';
import Graph from './graph/Graph.vue';
import GameRegistry from '../GameRegistry';

export default Vue.extend({
    name: 'Partition',
    props: [
        'game',
        'path',
        'guid',
    ],
    data(): {
        registry: GameRegistry;
        partition: Partition | null;
        activeInstance: string;
        types: { [type: string]: any };
        hierarchyVisible: boolean;
        graphVisible: boolean;
    } {
        return {
            registry: this.$registries[this.game],
            partition: null,
            activeInstance: '',
            types: {},
            hierarchyVisible: true,
            graphVisible: true,
        };
    },
    computed: {
        fileUrl(): string {
            return `/${this.game}/ebx/${this.path}.json`;
        },
        directoryUrl(): string {
            const parts = this.path.split('/');
            parts.pop();
            return `https://github.com/EmulatorNexus/Venice-EBX/tree/master/${parts.join('/')}`;
        },
    },
    mounted() {
        this.registry.resolveFile(this.path)
            .then(partition => this.partition = partition)
            .then(() => {
                if (this.partition && Object.keys(this.partition.instances).length == 1 && this.activeInstance === '') {
                    this.activeInstance = this.partition.instances[Object.keys(this.partition.instances)[0]].guid;
                }

                if (!location.hash) {
                    return;
                }

                const el = document.getElementById(location.hash.substring(1));
                if (!el) {
                    return;
                }

                const rect = el.getBoundingClientRect();
                scrollTo(0, rect.top);
            });
    },
    watch: {
        $route(to) {
            if (to.hash) {
                this.activeInstance = to.hash.substring(1);
            } else {
                this.activeInstance = '';
            }
        },
    },
    methods: {
        graphNodesChanged(nodes: Array<any>): void {
            this.graphVisible = nodes.length > 0;
        },
    },
    components: {
        HierarchyEntry,
        Graph,
    },
});
