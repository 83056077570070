












import Vue, {PropType} from 'vue';
import {Node} from 'rete';

import Instance from '../../ebx/Instance';

export default Vue.extend({
    name: 'SettingNode',
    props: {
        node: {
            type: Object as PropType<Node>,
            required: true,
        },
        instance: {
            type: Object as PropType<Instance>,
            required: true,
        },
    },
    computed: {
        setting(): string {
            return this.instance.fields.boolSettingName.value || this.instance.fields.intSettingName.value || this.instance.fields.floatSettingName.value;
        },
    },
});
