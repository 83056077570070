







































import Vue from 'vue';

function resolve(data: any, path: string): { [dir: string]: string } {
    if (!path.length) {
        return data;
    }

    let current = data;
    for (const part of path.split('/')) {
        current = current[part];
    }
    return current;
}

function sortFile(a: string, b: string): number {
    return a.localeCompare(b, 'en', {numeric: true}) || b.length - a.length;
}

export default Vue.extend({
    name: 'Directory',
    props: {
        game: String,
        path: {
            type: String,
            default: '',
        },
    },
    data(): { loading: boolean, directories: { [dir: string]: string } | null, files: Array<string> | null } {
        return {
            loading: true,
            directories: null,
            files: null,
        };
    },
    computed: {
        sortedDirectories(): Array<string> {
            const names: Array<string> = [];

            if (this.directories) {
                names.push(...Object.keys(this.directories).sort(sortFile));
            }

            return names;
        },
        sortedFiles(): Array<string> {
            const names: Array<string> = [];

            if (this.files) {
                names.push(...this.files.sort(sortFile));
            }

            return names;
        },
        cleanPath(): string {
            let path = this.path;
            if (!path.startsWith('/')) {
                path = '/' + path;
            }
            if (!path.endsWith('/')) {
                path += '/';
            }
            return path;
        },
    },
    async mounted() {
        const index = await fetch(`/${this.game}/files.json`).then(res => res.json());
        this.directories = resolve(index.tree, this.path);
        this.files = index.files[this.path];
        this.loading = false;
    },
});
