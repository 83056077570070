




























import Vue, {PropType} from 'vue';
import {copyToClipboard} from '../mixins/clipboard';
import GameRegistry from '../GameRegistry';
import Partition from '../ebx/Partition';
import Field from '../ebx/Field';

export default Vue.extend({
    name: 'Vec4Property',
    mixins: [
        copyToClipboard,
    ],
    props: {
        registry: {
            type: Object as PropType<GameRegistry>,
            required: true,
        },
        partition: {
            type: Object as PropType<Partition>,
            required: true,
        },
        field: {
            type: Object as PropType<Field<any>>,
            required: true,
        },
    },
});
