






























































import Vue, {PropType} from 'vue';

import {copyToClipboard} from '../mixins/clipboard';

import Field from '../ebx/Field';

import Vec3Property from './Vec3Property.vue';
import GameRegistry from '../GameRegistry';
import Partition from '../ebx/Partition';

export default Vue.extend({
    name: 'LinearTransformProperty',
    mixins: [copyToClipboard],
    props: {
        registry: {
            type: Object as PropType<GameRegistry>,
            required: true,
        },
        partition: {
            type: Object as PropType<Partition>,
            required: true,
        },
        field: {
            type: Object as PropType<Field<any>>,
            required: true,
        },
    },
    computed: {
        trans(): Field<any> {
            return new Field('trans', 'Vec3', this.field.value.trans);
        },
        forward(): Field<any> {
            return new Field('forward', 'Vec3', this.field.value.forward);
        },
        right(): Field<any> {
            return new Field('right', 'Vec3', this.field.value.right);
        },
        up(): Field<any> {
            return new Field('up', 'Vec3', this.field.value.up);
        },
        lua(): string {
            return `LinearTransform(${this.formatVec3(this.field.value.right)}, ${this.formatVec3(this.field.value.up)}, ${this.formatVec3(this.field.value.forward)}, ${this.formatVec3(this.field.value.trans)})`;
        },
    },
    methods: {
        formatVec3(vector: { x: number, y: number, z: number }): string {
            return `Vec3(${vector.x}, ${vector.y}, ${vector.z})`;
        },
    },
    components: {
        Vec3Property,
    },
});
