























































































































































import Vue, {PropType} from 'vue';

import events from '../../data/eventHashes.json';

import GameRegistry from '../GameRegistry';
import Instance from '../ebx/Instance';

export default Vue.extend({
    name: 'InstanceIdentifier',
    props: {
        registry: {
            type: Object as PropType<GameRegistry>,
            required: true,
        },
        instance: {
            type: Object as PropType<Instance>,
            required: true,
        },
        referenceLinks: {
            type: Boolean,
            default: () => true,
        },
    },
    methods: {
        resolveEvent(hash: string): string {
            return (events as { [hash: string]: string })[hash] || hash;
        },
    },
});
