























































import Vue, {PropType} from 'vue';
import {Node} from 'rete';
// @ts-ignore
import VueRenderPlugin from '../../../lib/rete/vue-render-plugin.common';

import Partition from '../../ebx/Partition';
import Instance from '../../ebx/Instance';

import DefaultNode from './DefaultNode.vue';
import SimpleValueNode from './SimpleValueNode.vue';
import Socket from './Socket.vue';
import CompareNode from './CompareNode.vue';
import LogicNode from './LogicNode.vue';
import MathOpNode from './MathOpNode.vue';
import DelayNode from './DelayNode.vue';
import RandomDelayNode from './RandomDelayNode.vue';
import SettingNode from './SettingNode.vue';

const simpleValueTypes = [
    'BoolEntityData',
    'IntEntityData',
    'FloatEntityData',
];

const logicTypes = [
    'NotEntityData',
    'AndEntityData',
    'OrEntityData',
    'Or4EntityData',
];

export default Vue.extend({
    name: 'InstanceNode',
    mixins: [
        VueRenderPlugin.mixin,
    ],
    props: {
        node: {
            type: Object as PropType<Node>,
            required: true,
        },
    },
    computed: {
        partition(): Partition {
            return this.instance.partition;
        },
        instance(): Instance {
            return this.node.data.instance as Instance;
        },
        nodeComponent() {
            if (simpleValueTypes.indexOf(this.instance.type) >= 0) {
                return SimpleValueNode;
            } else if (this.instance.type === 'CompareEntityData') {
                return CompareNode;
            } else if (this.instance.type === 'MathOpEntityData') {
                return MathOpNode;
            } else if (this.instance.type === 'DelayEntityData') {
                return DelayNode;
            } else if (this.instance.type === 'RandomDelayEntityData') {
                return RandomDelayNode;
            } else if (this.instance.type === 'SettingEntityData') {
                return SettingNode;
            } else if (logicTypes.indexOf(this.instance.type) >= 0) {
                return LogicNode;
            }

            return DefaultNode;
        },
        foreignPartition(): boolean {
            return this.partition !== this.node.data.sourcePartition;
        },
        realm(): string | undefined {
            if (!this.instance.fields.realm) {
                return undefined;
            }

            return this.instance.fields.realm.enumValue;
        },
    },
    components: {
        Socket,
    },
});
