

















































































import Vue, {PropType} from 'vue';

import {copyToClipboard} from '../mixins/clipboard';

import Partition from '../ebx/Partition';
import Instance from '../ebx/Instance';
import GameRegistry from '../GameRegistry';

export default Vue.extend({
    name: 'Instance',
    mixins: [
        copyToClipboard,
    ],
    props: {
        registry: {
            type: Object as PropType<GameRegistry>,
            required: true,
        },
        partition: {
            type: Object as PropType<Partition>,
            required: true,
        },
        instance: {
            type: Object as PropType<Instance>,
            required: true,
        },
        active: String,
    },
    data() {
        return {
            visible: false,
        };
    },
    mounted() {
        if (location.hash && location.hash.substring(1) === this.instance.guid) {
            this.visible = true;
        }
    },
    watch: {
        active(guid) {
            if (guid === this.instance.guid) {
                this.visible = true;
            }
        },
    },
    methods: {
        luaFindInstanceByGuid(partition: Partition, instance: Instance) {
            return `${instance.type}(ResourceManager:FindInstanceByGuid(Guid('${partition.guid}'), Guid('${instance.guid}')))`;
        },
        luaSearchForDataContainer(instance: Instance) {
            return `${instance.type}(ResourceManager:SearchForDataContainer('${instance.fields.name.value}'))`;
        },
        luaFindInstance(instance: Instance) {
            return `${instance.type}(partition:FindInstance(Guid('${instance.guid}')))`;
        },
    },
});
