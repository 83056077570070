









































































import Vue from 'vue';
import {capitalize, removeExtension} from '../filters';

export default Vue.extend({
    name: 'Viewer',
    data(): { footer: number } {
        return {
            footer: Math.floor(Math.random() * 4),
        };
    },
    computed: {
        game() {
            return this.$route.params.game;
        },
        breadcrumbs() {
            const path = this.$route.path;
            if (!path) {
                return [];
            }

            const crumbs = [];
            let currentPath = '';
            const parts = path.split('/');
            parts.shift();
            parts.shift();
            if (parts.length > 1 && parts[parts.length - 1] === 'graph') {
                parts.pop();
            }
            for (const part of parts) {
                let name = part;
                // Capitalize the game name
                if (crumbs.length <= 0) {
                    name = capitalize(name);
                } else if (crumbs.length === parts.length - 1) {
                    name = removeExtension(name);
                }

                currentPath += part;
                crumbs.push({
                    url: `/view/${currentPath}`,
                    name,
                });
                currentPath += '/';
            }

            return crumbs;
        },
    },
});
